// eslint-disable-next-line no-unused-vars
const ApplicantsSelectionJson = (_this) => {
  return {
    countCells: 18,
    orgMassive: [
      {
        dataField: 'num',
        cols: 1,
        rows: 1,
        text: '№',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonNum',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'docs',
        text: 'Сканы',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantJsonDocs',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'fio',
        cols: 2,
        rows: 1,
        text: 'Претендент',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonFio',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'orgInfo',
        cols: 3,
        rows: 1,
        text: 'Организация',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonOrgInfo',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left;`,
      },
      {
        dataField: 'spec',
        cols: 3,
        rows: 1,
        text: 'Специальность',
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'education',
        cols: 3,
        rows: 1,
        text: 'Обучение',
        variable: true,
        class: 'applicantsSelectionJsonOrgEducation',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'score',
        cols: 1,
        rows: 1,
        text: 'Суммарные Баллы',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonOrgScore',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; text-align: center`,
      },
      {
        dataField: 'konvert',
        cols: 2,
        rows: 1,
        text: 'Конверт и заявка',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantAdminJsonKonvert',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: center`,
      },
      {
        dataField: 'noScanList',
        text: 'Отсутств. сканы',
        cols: 2,
        rows: 1,
        variable: true,
        styles: 'border-left: none; display: flex; flex-direction: row',
        class: 'konvertRegistrationJsonNoScanList',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
    ],
  };
}

export default ApplicantsSelectionJson